.view_item {
  display: flex;
  flex-direction: column;
}

.view_item .view_item__label {
  font-size: 0.75rem;
  color: #666;
  margin-bottom: 2px;
}

.view_item .view_item__content {
  font-weight: 500;
  color: #333;
}
