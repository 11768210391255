.divider {
  border-bottom: 2px dashed #ccc4c4ee;
  padding-bottom: 2px;
  margin-bottom: 24px;
  line-height: 0;
  padding-left: 16px;
}
.divider > span {
  background-color: white;
  padding: 0 4px;
  color: rgba(39, 45, 69, 0.86);
  font-weight: bold;
}
